<template>
  <div class="shipment-box">
    <div class="searchOrder">
      <div class="list-title">Shipment</div>
      <a-card class="search-card" :body-style="{ padding: '16px 16px 0 16px' }">
        <a-form ref="formRef" :model="searchForm" layout="vertical" autoComplete="off">
          <a-row :gutter="[20, 20]">
            <a-col :span="10">
              <a-form-item field="tracking" label="Tracking">
                <a-input-search v-model="searchForm.tracking" placeholder="Please enter" allow-clear
                  @keydown.enter="searchShipment" />
              </a-form-item>
            </a-col>
            <a-col :span="10">
              <a-form-item field="status">
                <template #label>
                  Status
                  <a-tooltip>
                    <template #content>
                      <div>
                        shipments in transit can be batch operated
                        to call back or change address.
                      </div>
                    </template>
                    <icon-question-circle style="cursor: pointer"></icon-question-circle>
                  </a-tooltip>
                </template>
                <a-select v-model="searchForm.status" multiple :max-tag-count="3" allow-clear>
                  <a-option :value="k" v-for="item, k in status" :key="item">{{ item }}</a-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4" class="operate">
              <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset" cancel-text="Cancel"
                @ok="handleReset" class="warnPop">
                <a-button shape="round">Reset</a-button>
              </a-popconfirm>
              <a-button type="primary" shape="round" html-type="submit" @click="searchShipment">Search
              </a-button>
              <a-button shape="circle" @click="showSearch">
                <icon-double-down v-show="flag === false" />
                <icon-double-up v-show="flag === true" />
              </a-button>
            </a-col>
          </a-row>
          <div :style="{ display: flag === true ? 'block' : 'none' }">
            <a-row :gutter="[20, 20]">
              <a-col :span="5" style="width:190px;">
                <a-form-item field="service" label="Service">
                  <a-select v-model="searchForm.service" multiple :max-tag-count="2">
                    <a-option :value="0">N/A</a-option>
                    <a-option :value="101">Ground</a-option>
                    <a-option :value="102">Home</a-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item field="showPieces" label="Single/Multiple Pieces">
                  <a-select v-model="searchForm.showPieces" multiple>
                    <a-option :value="0">SPS </a-option>
                    <a-option :value="1">MPS</a-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item field="manifest_name" label="Manifest">
                  <a-config-provider :locale="enUS">
                    <a-select allow-search placeholder="E.g. 1132299310964" :filter-option="false"
                      v-model="searchForm.manifest_name" @search="manifestSearch" @change="manifestChange">
                      <a-option v-for="item of manifestOptions" :value="item.name" :key="item">{{ item.name }}
                      </a-option>
                    </a-select>
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="3" id="recipient">
                <a-form-item field="recipientZip" label="Recipient Zip">
                  <a-config-provider :locale="enUS">
                    <a-select allow-search allow-clear :filter-option="false" v-model="searchForm.recipientZip"
                      @change="fromChange" @search="fromZipSearch" placeholder="E.g. 00811">
                      <a-option v-for="item of fromOptions" :value="item.zip" :key="item">{{ item.zip }}</a-option>
                    </a-select>
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item field="orderId" label="Order ID">
                  <a-input v-model="searchForm.orderId" placeholder="E.g. 202208213849273" />
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item field="createTime" label="Create Time">
                  <a-config-provider :locale="enUS">
                    <a-range-picker v-model="searchForm.createTime" style="width: 260px;" :placeholder="['From', 'To']"
                      :format="dateFormate" />
                  </a-config-provider>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </a-form>
      </a-card>
      <div class="statType">
        Shipment
      </div>
      <!-- 表格 -->
      <div class="table-box">
        <a-config-provider :locale="enUS">
          <a-table :columns="listColumns" :data="listData.value" :pagination="false" :bordered="false"
            :scroll="{ x: tableWidth, y: tableHeight.value }" :row-selection="rowSelection" @select-all="changmsg"
            @select="selectVal" :selected-keys="changeform.operate_id" @cell-click="rowClick" style="padding-bottom:20px;"
            :loading="loading">
            <template #empty>
              <div v-if="listData.value.length === 0 && loading === false"
                :style="{ height: tableHeight.value - 35 + 'px' }" class="empty-cont">
                <img src="../../assets/images/empty/listempty.svg" alt="">
                <div>No results found</div>
              </div>
              <div v-if="loading === true" :style="{ height: tableHeight.value - 35 + 'px' }">
              </div>
            </template>
            <template #category="{ record }">
              <a-tag color="orange" v-if="record.category === 'Awaiting Shipment'">{{
                record.category
              }}</a-tag>
              <a-tag color="blue" v-if="record.category === 'In Transit'">{{
                record.category
              }}</a-tag>
              <a-tag color="red" v-if="record.category === 'Exceptional'">{{
                record.category
              }}</a-tag>
              <a-tag color="green" v-if="record.category === 'Delivered'">{{
                record.category
              }}</a-tag>
              <a-tag color="cyan" v-if="record.category === 'Dispatching'">{{
                record.category
              }}</a-tag>
              <a-tag v-if="record.category === 'Canceled'">{{
                record.category
              }}</a-tag>
              <a-tag color="purple" v-if="record.category === 'Callback'">{{
                record.category
              }}</a-tag>
              <a-tag color="purple" v-if="record.category === 'Change Address'">{{
                record.category
              }}</a-tag>
            </template>
            <template #sku="{ record }">
              <span v-if="record.sku">{{ record.sku }}</span>
              <span v-else>N/A</span>
            </template>
            <template #declaredvalue="{ record }">
              <span>${{ record.declaredvalue }}</span>
            </template>
            <template #estcost="{ record }">
              <a-tooltip position="right">
                <a-tag>${{ toThousands(record.estcost) }}</a-tag>
                <template #content>
                  <div v-for="val, k, i in record.quote_detail" :key="i">
                    <div>
                      <span v-if="k == 'Base Charge' || k == 'Surcharge'">{{ k }}:</span>
                      <span v-else>-{{ k }}:</span>
                      <span> {{ val }}</span>
                    </div>
                  </div>
                </template>
              </a-tooltip>
            </template>
            <template #tracking="{ record }">
              <div v-if="record.tracking_id">
                <span>{{ record.tracking_id }}
                </span>
                <a-tooltip content="Copy">
                  <icon-copy class="margin-l-10" @click.stop="copyVal(record.tracking_id)" />
                </a-tooltip>
              </div>
              <div v-else>N/A</div>
            </template>
            <template #reference="{ record }">
              <div>{{ record.reference1 }}</div>
              <div>{{ record.reference2 }}</div>
              <div>{{ record.reference3 }}</div>
            </template>
            <template #orderid="{ record }">
              <span>{{ record.order_unique_id }}</span>
              <a-tooltip content="Copy">
                <icon-copy class="margin-l-10" @click.stop="copyVal(record.order_unique_id)" />
              </a-tooltip>
            </template>
            <template #ponumber="{ record }">
              <span style="color:#1d2129;cursor: pointer;"
                @click.stop="$router.push('/order/list/listdetail?orderId=' + record.orderId)">{{
                  record.ponumber
                }}</span>
              <a-tooltip content="Copy">
                <icon-copy class="margin-l-10" @click.stop="copyVal(record.ponumber)" />
              </a-tooltip>
            </template>

            <template #action="{ record }">
              <a-tooltip content="Label">
                <a-button shape="circle" @click.stop="getLabel(record.label_file_name)" v-if="record.label_file_name"
                  class="m-r-8">
                  <icon-eye />
                </a-button>
              </a-tooltip>
              <a-tooltip content="Detail">
                <a-button shape="circle" v-if="record.tracking_id"
                  @click="goDetail(record.tracking_id, record.label_file_name)" class="m-r-8">
                  <icon-right />
                </a-button>
              </a-tooltip>
              <a-tooltip content="New Order">
                <a-button v-if="record.newOrderId > 0" shape="circle" type="primary"
                  @click="newOrderDetail(record.newOrderId)">
                  <icon-file />
                </a-button>
              </a-tooltip>
            </template>
          </a-table>
        </a-config-provider>
        <!--设置展示的弹框 -->
        <div class="transfer" v-show="setFlag">
          <div style="width:220px" class="setList">
            <a-table :data="leftData" @change="handleChange" :draggable="{ type: 'handle', width: 40 }"
              :row-selection="setSelection" :scroll="{ y: 200 }" :bordered="false" :pagination="false"
              @select-all="selectAll" @select="setSelect" :selected-keys="defaultSelect">
              <template #columns>
                <a-table-column title="List Setting" data-index="title"></a-table-column>
              </template>
            </a-table>
            <div class="transfer-btn">
              <a-button size="mini" @click="setDefault">default</a-button>
              <a-button type="primary" class="normal-btn" size="mini" @click="setVal"
                :disabled="defaultSelect.length < 1">
                save
              </a-button>
            </div>
            <div class="SelectNum">
              {{ defaultSelect.length }} / {{ leftData.length }}
            </div>
          </div>
        </div>
      </div>
      <div class="add-page" :style="changeform.operate_id.length > 0 ? 'padding-bottom:96px' : 'padding-bottom:0'">
        <a-space>
          <span>Total : {{ shipmentTotal }} shipments</span>
          <a-button :disabled="currentpage == 1" @click="clickMinus" type="text">
            <icon-left />
          </a-button>
          <span class="currentpage">{{ currentpage }}</span>
          <div>/</div>
          <div>{{ totalPages }}</div>
          <a-button :disabled="currentpage == totalPages || totalPages == 0" @click="clickAdd" type="text">
            <icon-right />
          </a-button>
          <a-select :style="{ width: '134px' }" v-model="pageSize" @change="selectPage">
            <a-option :value="20">20 / Page</a-option>
            <a-option :value="50">50 / Page</a-option>
            <a-option :value="100">100 / Page</a-option>
            <a-option :value="200">200 / Page</a-option>
          </a-select>
          <a-button shape="circle" id="setBtn">
            <icon-settings />
          </a-button>
        </a-space>
      </div>
      <div class="addnew-footer" v-if="changeform.operate_id.length > 0">
        <a-space>
          <a-checkbox v-model="checkedValue" @change="changmsg" class="itemNum">
            {{ changeform.operate_id.length }} items
          </a-checkbox>
          <a-button shape="round" status="warning" :disabled="changeDisable" @click="moreChange"
            v-if="changeDisable === false && changeform.operate_id.length > 0">Change
            Address
          </a-button>
          <a-popconfirm content="Are you sure to call back?" type="warning" ok-text="Call Back" cancel-text="Cancel"
            @ok="moreCallBack" class="warnPop">
            <a-button shape="round" status="warning" v-if="callDisable === false && changeform.operate_id.length > 0">
              Call Back</a-button>
          </a-popconfirm>
          <a-popconfirm content="Are you sure to cancel shipments you selected?" type="warning"
            ok-text="Cancel Shipment(s)" cancel-text="Back" @ok="moreCancel" class="warnPop">
            <a-button shape="round" status="warning" v-if="cancelDisable === false && changeform.operate_id.length > 0">
              Cancel</a-button>
          </a-popconfirm>
        </a-space>
      </div>
      <!-- 点击tracking的弹框 -->
      <div id="click-teacking">
        <a-drawer v-model:visible="trackingVisible" ok-text="Change Address" cancel-text="Return" title-align="start"
          :render-to-body="false" @cancel="trackingCancel" :width="465">
          <template #title>
            <a-space>
              <a-tag color="orange" v-if="trackData.trackVal.category === 'Awaiting Shipment'">{{
                trackData.trackVal.category
              }}</a-tag>
              <a-tag color="blue" v-if="trackData.trackVal.category === 'In Transit'">{{
                trackData.trackVal.category
              }}</a-tag>
              <a-tag color="red" v-if="trackData.trackVal.category === 'Exceptional'">{{
                trackData.trackVal.category
              }}</a-tag>
              <a-tag color="green" v-if="trackData.trackVal.category === 'Delivered'">{{
                trackData.trackVal.category
              }}</a-tag>
              <a-tag color="cyan" v-if="trackData.trackVal.category === 'Dispatching'">{{
                trackData.trackVal.category
              }}</a-tag>
              <a-tag v-if="trackData.trackVal.category === 'Canceled'">{{
                trackData.trackVal.category
              }}</a-tag>
              <a-tag color="purple" v-if="trackData.trackVal.category === 'Callback'">{{
                trackData.trackVal.category
              }}</a-tag>
              <a-tag color="purple" v-if="trackData.trackVal.category === 'Change Address'">{{
                trackData.trackVal.category
              }}</a-tag>
              <span>Tracking :{{ trackData.trackVal.tracking_id }}</span>
              <icon-copy @click="copyVal(trackData.trackVal.tracking_id)" />
            </a-space>
          </template>
          <!-- <div id="shipMap" class="rolad-line"></div> -->
          <div class="proofImg" v-if="trackData.trackVal.category === 'Delivered' && trackData.trackVal.photograph">
            <div class="proof-til">Proof of receipt</div>
            <img :src="proofReceipt" alt="" />
            <div class="proof-footer">
              <a-space size="large">
                <a target="_blank" :href="proofReceipt + '?' + Math.random"><svg width="12" height="10"
                    viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M0.254251 4.69241C1.8668 2.09115 3.7718 0.624756 6.00005 0.624756C8.2283 0.624756 10.1333 2.09115 11.7458 4.69241L11.9364 4.99976L11.7458 5.30711C10.1333 7.90836 8.2283 9.37476 6.00005 9.37476C3.7718 9.37476 1.8668 7.90836 0.254251 5.30711L0.0637207 4.99976L0.254251 4.69241ZM1.4415 4.99976C2.90384 7.23557 4.43236 8.20809 6.00005 8.20809C7.56773 8.20809 9.09626 7.23557 10.5586 4.99976C9.09626 2.76394 7.56773 1.79142 6.00005 1.79142C4.43236 1.79142 2.90384 2.76394 1.4415 4.99976ZM6.00005 4.12476C5.5168 4.12476 5.12505 4.51651 5.12505 4.99976C5.12505 5.48301 5.5168 5.87476 6.00005 5.87476C6.4833 5.87476 6.87505 5.48301 6.87505 4.99976C6.87505 4.51651 6.4833 4.12476 6.00005 4.12476ZM3.95838 4.99976C3.95838 3.87217 4.87247 2.95809 6.00005 2.95809C7.12763 2.95809 8.04171 3.87217 8.04171 4.99976C8.04171 6.12734 7.12763 7.04142 6.00005 7.04142C4.87247 7.04142 3.95838 6.12734 3.95838 4.99976Z"
                      fill="white" />
                  </svg></a>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                  style="padding-right:20px;" @click="downloadImg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.41667 6.6742V0.458008H6.58333V6.67482L8.23356 5.02459L9.05852 5.84955L6.00031 8.90776L2.9421 5.84955L3.76706 5.02459L5.41667 6.6742ZM1.91667 10.3747V9.20801H0.75V11.5413H11.25V9.20801H10.0833V10.3747H1.91667Z"
                    fill="white" />
                </svg>
              </a-space>
            </div>
          </div>
          <a-card style="margin-top:20px;">
            <template #title>
              <!-- Order ID#{{ trackData.trackVal.order_unique_id }}
              <icon-copy @click="copyVal(trackData.trackVal.order_unique_id)" /> -->
              <div style="display:flex;justify-content:space-between;">
                <div>
                  Order ID#{{ trackData.trackVal.order_unique_id }}
                  <icon-copy @click="copyVal(trackData.trackVal.order_unique_id)" />
                </div>
                <a-button v-if="trackData.trackVal.can_add_note === 1" shape="round" size="mini" @click="openNote">+ Add
                  Notes</a-button>
              </div>
            </template>
            <a-timeline>
              <a-timeline-item
                :label="trackData.trackVal.sender.city + ',' + trackData.trackVal.sender.state + ',' + trackData.trackVal.sender.country"
                lineType="dashed">
                Shipper:
              </a-timeline-item>
              <a-timeline-item lineType="dashed"
                :label="trackData.trackVal.recipient.city + ',' + trackData.trackVal.recipient.state + ',' + trackData.trackVal.recipient.country"
                dotColor="#C9CDD4">
                Recipient:
              </a-timeline-item>
            </a-timeline>
            <div class="notice" v-if="trackData.trackVal.notes.length > 0">
              <div class="til">Notes:</div>
              <div class="notice-cont" v-for="note, i in trackData.trackVal.notes" :key="i">
                <div class="who">From: {{ note.from_desc }}</div>
                <div class="cont">{{ note.content }}</div>
                <div class="time">{{ note.created_at }}</div>
              </div>
            </div>
          </a-card>
          <div class="logistics">
            <div class="road-steps">Details of Logistics</div>
            <div class="proof" v-if="trackData.trackVal.category === 'Delivered' && trackData.trackVal.autograph">
              Proof of receipt:
              <a-tooltip content="Download">
                <a-button shape="circle" style="margin-left:10px;" @click="downloadsign">
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M4.5 5.57816V0.25H5.5V5.5787L6.91448 4.16422L7.62159 4.87132L5.00027 7.49264L2.37895 4.87132L3.08605 4.16422L4.5 5.57816ZM1.5 8.75V7.75H0.5V9.75H9.5V7.75H8.5V8.75H1.5Z"
                      fill="#4E5969" />
                  </svg>
                </a-button>
              </a-tooltip>
            </div>
          </div>
          <div class="logs">
            <a-timeline>
              <a-timeline-item v-for="item in  trackData.trackVal.logs" :key="item" :label="item.date" dotColor="#ECECEC">
                {{ item.description }}<br />
                <a-typography-text type="secondary" :style="{ fontSize: '12px', marginTop: '4px' }">{{ item.location }}
                </a-typography-text>
              </a-timeline-item>
            </a-timeline>
          </div>
          <template #footer>
            <div class="operate-btn">
              <a-space>
                <a-button shape="circle" @click="LookTrackLabel">
                  <icon-eye />
                </a-button>
                <a-popconfirm
                  content="Are you sure to Call Back?
                                                                                                    You can go to Call Back Order
                                                                                                     to view the order(s)."
                  type="warning" ok-text="Call Back" cancel-text="Cancel"
                  @ok="trackCallBack(trackData.trackVal.shipment_id)" class="warnPop">
                  <a-button v-if="trackData.trackVal.can_callback" status="warning" shape="round">
                    Call Back</a-button>
                </a-popconfirm>
                <a-button v-if="trackData.trackVal.can_claim" shape="round" status="warning"
                  @click="goClaim">Claim</a-button>
                <a-popconfirm
                  content="Are you sure to cancel shipments you selected?
                                                                                                     You can't undo this action."
                  type="warning" ok-text="Cancel Shipment(s)" cancel-text="Back"
                  @ok="trackCancel(trackData.trackVal.shipment_id)" class="warnPop">
                  <a-button v-if="trackData.trackVal.can_cancel" shape="round" status="warning">Cancel</a-button>
                </a-popconfirm>
                <a-button v-if="trackData.trackVal.can_change_address" shape="round" status="warning"
                  @click="trackChangeAddress(trackData.trackVal.shipment_id)" class="warnPop">Change Address
                </a-button>
                <a-button v-if="trackData.trackVal.revised_order_id > 0" type="primary"
                  @click="newOrderDetail(trackData.trackVal.revised_order_id)" shape="round">New Order
                </a-button>
              </a-space>
            </div>
          </template>
        </a-drawer>
      </div>
      <!-- 修改地址的弹框 -->
      <div id="change-address-frame">
        <a-modal v-model:visible="visible" ok-text="Save" cancel-text="Reset" title-align="start" :render-to-body="false"
          @cancel="handleCancel" :width="520">
          <template #title>
            <div class="change-title">
              <span class="til">Change Address</span>
            </div>
          </template>
          <a-form :model="addressform" layout="vertical">
            <a-form-item field="name" label="Address">
              <a-config-provider :locale="enUS">
                <a-select @change="toAddressVal" v-model="recipient">
                  <a-option v-for="item, i in toAddress.option" :value="item.name" :key="i">{{ item.name }}
                  </a-option>
                </a-select>
              </a-config-provider>
            </a-form-item>
          </a-form>
          <a-form :model="changeform" ref="formAddress" size="medium" layout="vertical">
            <a-row :gutter="[20, 20]">
              <a-col :span="12">
                <a-form-item field="to_name" label="Name" :rules="[
                  { required: true, message: 'Name is required' },
                  { maxLength: 35, minLength: 2, message: '2-35 characters' }
                ]">
                  <a-input v-model="changeform.to_name" placeholder="Required 2-35 characters" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="to_company" label="Company Name"
                  :rules="[{ maxLength: 35, minLength: 2, message: '2-35 characters' }]">
                  <a-input v-model="changeform.to_company" placeholder="Required 2-35 characters" />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item field="to_street1" label="Address Line1" :rules="[
                  { required: true, message: 'Address line1 is required' },
                  { maxLength: 35, minLength: 4, message: '4-35 characters' }
                ]">
                  <a-input v-model="changeform.to_street1" placeholder="Required 4-35 characters" />
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item field="to_street2" label="Address Line2"
                  :rules="[{ maxLength: 35, minLength: 1, message: '1-35 characters' }]">
                  <a-input v-model="changeform.to_street2" placeholder="Optional 1-35 characters" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="to_zip" label="Zip Code" :rules="[
                  {
                    required: true,
                    type: 'number',
                    message: 'Zip code is required'
                  },
                  { minLength: 5, maxLength: 10, message: '5-10 characters' }
                ]">
                  <a-config-provider :locale="enUS">
                    <a-select allow-search placeholder="Required 5-10 characters" v-model="changeform.to_zip"
                      @change="zipChange" @search="ZipSearch" allow-clear :loading="zipLoading" :options="zipOptions"
                      autocomplete="off">
                      <template #empty>
                        <a-empty v-if="(zipIsempty === true)" />
                        <div class="empty" v-if="(zipZiperror === true)">
                          <a-space>
                            <icon-info-circle />
                            Zip error
                          </a-space>
                        </div>
                        <div class="noarea" v-if="(zipNoarea === true)">
                          <icon-empty />
                          Not in the delivery service area
                        </div>
                      </template>
                    </a-select>
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="to_city" label="City" :rules="[
                  { required: true, message: 'city is required' },
                  { maxLength: 35, minLength: 2, message: '2-35 characters' }
                ]">
                  <a-input v-model="changeform.to_city" placeholder="Required 2-35 characters" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="to_state" label="State" :rules="[{ required: true, message: 'state is required' }]">
                  <a-config-provider :locale="enUS">
                    <a-select v-model="changeform.to_state" placeholder="Please select" allow-search>
                      <a-option v-for="item in state" :key="item" :value="item.code">{{ item.code }} - {{ item.name }}
                      </a-option>
                    </a-select>
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="to_country" label="Country"
                  :rules="[{ required: true, message: 'Country is required' }]">
                  <a-select v-model="changeform.to_country" placeholder="Please select" disabled>
                    <a-option value="US">United State</a-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="9">
                <a-form-item field="to_phone" label="Phone"
                  :rules="[{ minLength: 10, maxLength: 15, message: '10-15 characters' }]">
                  <a-input v-model="changeform.to_phone" placeholder="Optional 10-15 characters" />
                  <a-config-provider :locale="enUS">
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="to_extension" label="Extension"
                  :rules="[{ minLength: 1, maxLength: 6, message: '1-6 characters' }]">
                  <a-input v-model="changeform.to_extension" placeholder="Optional" />
                </a-form-item>
              </a-col>
              <a-col :span="9">
                <a-form-item field="to_email" label="Email" :rules="[
                  {
                    match: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
                    message: 'email validation failed'
                  }
                ]">
                  <a-input v-model="changeform.to_email" placeholder="Optional" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
          <template #footer>
            <div class="change-footer">
              <a-space>
                <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset"
                  cancel-text="Cancel" @ok="resetForm" class="warnPop">
                  <a-button shape="round">Reset</a-button>
                </a-popconfirm>
                <a-button type="primary" shape="round" html-type="submit" @click="submitChange">Change Address
                </a-button>
              </a-space>
            </div>
          </template>
        </a-modal>
      </div>
      <!-- 添加备注的弹框 -->
      <div id="notePop">
        <a-modal v-model:visible="noteVisible" ok-text="Save" cancel-text="Reset" :render-to-body="false"
          @cancel="cancelNote" :width="520" title="Add Notes">
          <a-form :model="noteForm" ref="noteRef" size="medium" layout="vertical">
            <a-form-item field="visible" label="Visible to whom">
              <a-checkbox-group v-model="noteForm.visible">
                <a-checkbox v-for="who, k in whoVisible" :value="k" :key="who">{{ who }}</a-checkbox>
              </a-checkbox-group>
            </a-form-item>
            <a-form-item field="content" label="Content" :rules="[
              { required: true, message: 'Please enter' }
            ]" :validate-trigger="['blur']">
              <a-textarea v-model="noteForm.content" placeholder="Please enter" allow-clear />
            </a-form-item>
          </a-form>
          <template #footer>
            <div class="change-footer">
              <a-space>
                <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset"
                  cancel-text="Cancel" @ok="resetNote" class="warnPop">
                  <a-button shape="round">Reset</a-button>
                </a-popconfirm>
                <a-button type="primary" shape="round" html-type="submit" @click="submitNote">Submit
                </a-button>
              </a-space>
            </div>
          </template>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, onMounted, ref, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import local from '@/utils/local.js'
// 使用loading
import { load } from '@/utils/loading.js'
import { Message, Notification, Button } from '@arco-design/web-vue'
// import { searchLoginTracking } from '@/api/homepage.js'
import { shipmentCallback, addressList, changeAddress, orderDetail, getZipCode, shipmentCancel, shipmentBatchCancel, searchLoginTracking, shipmentAddNote } from '@/api/order.js'
import {
  IconRight, IconLeft, IconQuestionCircle, IconDoubleDown, IconDoubleUp, IconCopy, IconEye, IconFile, IconSettings, IconInfoCircle, IconEmpty
} from '@arco-design/web-vue/es/icon'
// 引入api接口
import { shipmentList, getManifestListByName } from '@/api/shipment.js'
import { toThousands } from '@/utils/handleNumber.js'
const loading = ref(false)
// 搜索展开隐藏
const flag = ref(false)
// 获取 loacal里面的公共配置
const status = JSON.parse(local.get('configInfo')).shipment_category
const state = JSON.parse(local.get('configInfo')).state
// 搜索内容
let searchForm = reactive({
  tracking: '',
  status: ['awaitShip', 'callback', 'canceled', 'changeAddress', 'delivered', 'dispatching', 'exceptional', 'inTransit'],
  service: [0, 101, 102],
  showPieces: [0, 1],
  manifest_name: '',
  recipientZip: '',
  orderId: '',
  createTime: ['', ''],
  manifest_id: '',
  category: ''
})
const route = useRoute()
const router = useRouter()
// orderlist 跳转过来------------------------------------
let manifestId = ref(route.query.id)
let manifestName = ref(route.query.name)
if (manifestId.value) {
  searchForm.manifest_id = manifestId.value
}
if (manifestName.value) {
  searchForm.manifest_name = manifestName.value
}
// 表格复选框
const rowSelection = {
  type: 'checkbox',
  showCheckedAll: true
}
// 获取窗口的高度
const tableHeight = reactive({ value: 0 })
const tableWidth = ref(0)
tableHeight.value = document.body.clientHeight - 420
tableWidth.value = document.body.clientWidth - 50
onMounted(() => {
  // 改变窗口高度
  window.onresize = () => {
    tableWidth.value = document.body.clientWidth - 50
    if (flag.value) {
      tableHeight.value = document.body.clientHeight - 510
    } else {
      tableHeight.value = document.body.clientHeight - 420
    }
  }
})
const showSearch = () => {
  flag.value = !flag.value
  if (flag.value) {
    tableHeight.value = document.body.clientHeight - 510
  } else {
    tableHeight.value = document.body.clientHeight - 420
  }
}
// 自动填充zip
const fromOptions = ref([])
const fromZipSearch = async (value) => {
  if (value.length > 0) {
    const msg = await getZipCode({
      term: value
    })
    if (msg.data) {
      fromOptions.value = msg.data
    } else {
      fromOptions.value = []
    }
  }
  if (value.length > 5) {
    searchForm.recipientZip = value
  }
}
// 通过zip回填recipient
const fromChange = (value) => {
  if (fromOptions.value !== []) {
    fromOptions.value.forEach((item) => {
      if (item.value === value) {
        searchForm.recipientZip = item.zip
      }
    })
  }
}
const manifestOptions = ref([])
// manifest name搜索
const manifestSearch = async (val) => {
  const msg = await getManifestListByName({
    term: val
  })
  if (msg.code === 0 && msg.data.length > 0) {
    manifestOptions.value = msg.data
  } else {
    manifestOptions.value = []
    form.manifest_name = val
  }
}
const manifestChange = (val) => {
  manifestOptions.value.forEach((item) => {
    if (item.name === val) {
      form.manifest_name = val
    }
  })
}
// 当前页
const currentpage = ref(1)
// 总的shipment
const shipmentTotal = ref(0)
// 总页数
const totalPages = ref(0)
// 表格数据
let listData = reactive({ value: [] })
// 表格配置
const listColumns = ref([
  {
    title: 'Tracking ID',
    dataIndex: 'tracking_id',
    slotName: 'tracking',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 180
  },
  {
    key: 'ponumber',
    title: 'PO Number',
    dataIndex: 'ponumber',
    slotName: 'ponumber',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 220
  },
  {
    title: 'Recipient Name',
    dataIndex: 'recipientname',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 200
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal
        if (a.key > b.key) {
          sortVal = 1
        } else if (a.key < b.key) {
          sortVal = -1
        } else {
          sortVal = 0
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal
      }
    },
    width: 200
  },
  {
    title: 'Status',
    dataIndex: 'category',
    slotName: 'category',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 180
  },
  {
    title: 'Sku',
    dataIndex: 'sku',
    slotName: 'sku',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    title: 'Dimension(In)',
    dataIndex: 'dimension',
    slotName: 'dimension',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    title: 'Weight(Lb)',
    dataIndex: 'weight',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    title: 'Declared Value',
    dataIndex: 'declaredvalue',
    slotName: 'declaredvalue',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 180
  },
  {
    title: 'Est.Cost',
    dataIndex: 'estcost',
    slotName: 'estcost',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal;
        if (parseFloat(a.estcost) > parseFloat(b.estcost)) {
          sortVal = 1;
        } else if (parseFloat(a.estcost) < parseFloat(b.estcost)) {
          sortVal = -1;
        } else {
          sortVal = 0;
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal;
      }
    },
    width: 100
  },
  {
    title: 'Action',
    dataIndex: 'action',
    fixed: 'right',
    slotName: 'action',
    width: 150
  }
])
// 获取shipment list
const pageSize = ref(20)
const getShipmentList = async () => {
  loading.value = true
  const msg = await shipmentList({
    service_type: searchForm.service,
    to_zip: searchForm.recipientZip,
    order_unique_id: searchForm.orderId,
    created_at_start: searchForm.createTime[0],
    created_at_end: searchForm.createTime[1],
    status: null,
    category: searchForm.status,
    order_id: searchForm.orderId,
    manifest_id: searchForm.manifest_id,
    mps: searchForm.showPieces,
    driver_id: null,
    merchant_id: null,
    current_hub: null,
    tracking: searchForm.tracking,
    job_id: null,
    driver_settlement_id: null,
    sort: null,
    manifest_name: searchForm.manifest_name,
    page: currentpage.value,
    pageCount: pageSize.value
  })
  if (msg.code === 0) {
    loading.value = false
    shipmentTotal.value = msg.data.totalCount
    totalPages.value = msg.data.totalPages
    currentpage.value = Number(msg.data.currentPage)
    let info = msg.data.data
    let currentData = []
    for (let i = 0; i < info.length; i++) {
      currentData.push({ key: info[i].id, created_at: info[i].created_at, category: info[i].category, tracking_id: info[i].tracking_id, ponumber: info[i].ponumber, service_type: info[i].service_type, estcost: info[i].estimate_shipping_charge, clallback: info[i].can_callback, cancel: info[i].can_cancel, changeaddress: info[i].can_change_address, claim: info[i].can_claim, label_file_name: info[i].label_file_name, orderId: info[i].order_id, sku: info[i].sku, dimension: info[i].dimension, weight: info[i].weight, declaredvalue: info[i].insurance, reference1: info[i].reference1, reference2: info[i].reference2, reference3: info[i].reference3, invoicenumber: info[i].invoice_number, order_unique_id: info[i].order_unique_id, actcost: info[i].actual_shipping_charge, adjust: info[i].adjust_amount, quote_detail: info[i].quote_detail, newOrderId: info[i].revised_order_id, refund: info[i].refundAmount, recipientname: info[i].recipient_name, estimateddeliverydate: info[i].est_delivered_date, delivereddate: info[i].delivered_date })
    }
    listData.value = currentData
  } else {
    loading.value = false
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 表格渲染
getShipmentList()
// 点击页数减
const clickMinus = () => {
  currentpage.value--
  getShipmentList()
}
// 点击页数加
const clickAdd = () => {
  currentpage.value++
  getShipmentList()
}
// 获取label------------------------------------------
const getLabel = (label) => {
  window.open(label, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
}
// 点击tracking抽屉----------------------------------------
// tracking 弹框
const trackingVisible = ref(false)
// tracking 弹框的数据
let trackData = reactive({
  trackVal: {
    sender: {},
    recipient: {},
    notes: []
  }
})
// const script = ref('')
// onMounted(() => {
//   script.value = document.createElement('script')
// })
const clickTrackingOrderId = ref(null)
// 起点
// let startAddress = ref('')
// 终点
// let endAddress = ref('')
const currentView = ref('')
// 签收pingzheng
let proofReceipt = ref('')
// 点击tracking
const drawerTracking = ref('')
const rowClick = async (record) => {
  currentView.value = record.label_file_name
  if (!record.tracking_id) {
    return
  }
  clickTrackingOrderId.value = record.orderId
  drawerTracking.value = record.tracking_id
  rowDetail()
}
const rowDetail = async () => {
  const msg = await searchLoginTracking({
    trackingId: drawerTracking.value
  })
  if (msg.code === 0) {
    if (!msg.data.photograph) {
      proofReceipt.value = msg.data.pod_file
    } else {
      proofReceipt.value = msg.data.photograph
    }
    trackData.trackVal = msg.data
    // let lastLocation = msg.data.lastLocation
    // startAddress.value = lastLocation.street1 + ',' + lastLocation.street2 + ',' + lastLocation.city + ',' + lastLocation.state + ',' + lastLocation.zip + ',' + lastLocation.country
    // let endLocation = msg.data.recipient
    // endAddress.value = endLocation.street1 + ',' + endLocation.street2 + ',' + endLocation.city + ',' + endLocation.state + ',' + endLocation.zip + ',' + endLocation.country
    // script.value.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCYZLGxJ3yCFkGJmmT1gOOAh-_7KP1H5f8&callback=shipMap&libraries=&v=weekly'
    // script.value.setAttribute('defer', 'defer')
    // document.body.appendChild(script.value)
    nextTick(function () {
      trackingVisible.value = true
      // if (directionsService !== undefined) {
      //   calculateAndDisplayRoute(directionsService, directionsRenderer)
      // }
    })
  } else {
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// let directionsService
// let directionsRenderer
// window.shipMap = function () {
//   directionsService = new google.maps.DirectionsService()
//   directionsRenderer = new google.maps.DirectionsRenderer()
//   const map = new google.maps.Map(document.getElementById("shipMap"), {
//     zoom: 10
//   })
//   directionsRenderer.setMap(map)
//   // 刚进入页面时渲染函数
//   calculateAndDisplayRoute(directionsService, directionsRenderer)
// }
// function calculateAndDisplayRoute (directionsService, directionsRenderer) {
//   directionsService.route({
//     origin: startAddress.value,
//     destination: endAddress.value,
//     optimizeWaypoints: true,
//     travelMode: google.maps.TravelMode.DRIVING
//   }, (response, status) => {
//     if (status === "OK") {
//       directionsRenderer.setDirections(response)
//     }
//   }
//   )
// }
// 修改地址--------------------------------------------------
// 修改地址弹框
const visible = ref(false)
const addressform = reactive({
  address: ''
})
const changeform = reactive({
  to_name: '',
  to_address: '',
  to_company: '',
  to_phone: '',
  to_street1: '',
  to_street2: '',
  to_city: '',
  to_state: '',
  to_country: 'US',
  to_zip: '',
  to_email: '',
  to_extension: '',
  operate_id: []
})
const formAddress = ref(null)
// 关闭修改地址弹框
const handleCancel = () => {
  recipient.value = ''
  formAddress.value.resetFields()
  visible.value = false
}
// recipient Address 选择的值
const recipient = ref('')
// recipient Address 选择回填
const toAddressVal = (val) => {
  recipient.value = val
  toAddress.option.forEach((item) => {
    if (item.name === val) {
      changeform.to_name = item.name
      changeform.to_company = item.company
      changeform.to_street1 = item.street1
      changeform.to_street2 = item.street2
      changeform.to_city = item.city
      changeform.to_state = item.state
      changeform.to_zip = item.zip
      changeform.to_phone = item.phone
      changeform.to_extension = item.extension
      changeform.to_email = item.email
    }
  })
}
// 获取recipient Address
const toAddress = reactive({
  option: []
})
const getToAddress = async () => {
  const msg = await addressList({
    page: 1,
    type: 2,
    searchItem: ''
  })
  if (msg.code === 0 && msg.data) {
    toAddress.option = msg.data.data
  }
}
getToAddress()
const tracking = ref('')
// 点击弹框 change address
const trackChangeAddress = async (val, track) => {
  visible.value = true
  changeform.operate_id.push(val)
  tracking.value = track
  const msg = await orderDetail({
    order_id: clickTrackingOrderId.value
  })
  if (msg.code === 0) {
    changeform.to_name = msg.data.to_name
    changeform.to_company = msg.data.to_company
    changeform.to_street1 = msg.data.to_street1
    changeform.to_street2 = msg.data.to_street2
    changeform.to_city = msg.data.to_city
    changeform.to_state = msg.data.to_state
    changeform.to_zip = msg.data.to_zip
    changeform.to_phone = msg.data.to_phone
    changeform.to_extension = msg.data.to_extension
    changeform.to_email = msg.data.to_email
  }
}
// 批量修改地址
const moreChange = () => {
  visible.value = true
}
// dianji
// 重置修改表单、
const resetForm = () => {
  recipient.value = ''
  formAddress.value.resetFields()
}
// 提交修改
const submitChange = () => {
  formAddress.value.validate(async (record) => {
    if (!record) {
      visible.value = false
      trackingVisible.value = false
      load.show('Changing Address...')
      const msg = await changeAddress({
        to_name: changeform.to_name,
        to_company: changeform.to_company,
        to_street1: changeform.to_street1,
        to_street2: changeform.to_street2,
        to_city: changeform.to_city,
        to_state: changeform.to_state,
        to_zip: changeform.to_zip,
        is_residential: null,
        to_country: changeform.to_country,
        to_phone: changeform.to_phone,
        to_extension: changeform.to_extension,
        to_email: changeform.to_email,
        shipment_ids: changeform.operate_id
      })
      if (msg.code === 0) {
        load.hide()
        Notification.success({
          title: 'Success',
          content: 'Changed Address.'
        })
        // 清空id
        changeform.operate_id = []
        currentpage.value = 1
        listData.value = []
        getShipmentList()
      } else if (msg.code === 11) {
        load.hide()
        // 余额不足
        Notification.warning({
          id: 'payShipment1',
          title: 'Insufficient Balance!',
          content: 'The balance is insufficient, getting labels has been stopped, and the order will continue automatically after recharging.',
          duration: 0,
          closable: true,
          footer: <Button type="primary" size="small" shape="round" onClick={closeNotification1}>
            Recharge
          </Button>
        })
      } else {
        load.hide()
        Notification.error({
          title: 'Error',
          content: msg.message
        })
      }
    }
  })
}
const closeNotification1 = () => {
  Notification.remove('payShipment1')
  let routerUrl = router.resolve({ path: '/usercenter/recharge' })
  window.open(routerUrl.href, '_blank')
}
// 关闭TRACKING弹框
const trackingCancel = () => {
  visible.value = false
}
// calll back--------------------------------------------------------

// 弹框 call back
const trackCallBack = async (shipmentId) => {
  changeform.operate_id.push(shipmentId)
  trackingVisible.value = false
  load.show('Calling Back...')
  const msg = await shipmentCallback({
    ids: changeform.operate_id
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Called Back.'
    })
    changeform.operate_id = []
    currentpage.value = 1
    getShipmentList()
  } else if (msg.code === 11) {
    load.hide()
    // 余额不足
    Notification.warning({
      id: 'payShipment2',
      title: 'Insufficient Balance!',
      content: 'The balance is insufficient, getting labels has been stopped, and the order will continue automatically after recharging.',
      duration: 0,
      closable: true,
      footer: <Button type="primary" size="small" shape="round" onClick={closeNotification2}>
        Recharge
      </Button>
    })
    currentpage.value = 1
    changeform.operate_id = []
    getShipmentList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
const closeNotification2 = () => {
  Notification.remove('payShipment2')
  let routerUrl = router.resolve({ path: '/usercenter/recharge' })
  window.open(routerUrl.href, '_blank')
}
// 批量call back
const moreCallBack = async () => {
  load.show('Calling Back...')
  const msg = await shipmentCallback({
    ids: changeform.operate_id
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Called Back.'
    })
    changeform.operate_id = []
    getShipmentList()
  } else if (msg.code === 11) {
    load.hide()
    // 余额不足
    Notification.warning({
      id: 'payShipment3',
      title: 'Insufficient Balance!',
      content: 'The balance is insufficient, getting labels has been stopped, and the order will continue automatically after recharging.',
      duration: 0,
      closable: true,
      footer: <Button type="primary" size="small" shape="round" onClick={closeNotification3}>
        Recharge
      </Button>
    })
    currentpage.value = 1
    changeform.operate_id = []
    getShipmentList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
const closeNotification3 = () => {
  Notification.remove('payShipment3')
  let routerUrl = router.resolve({ path: '/usercenter/recharge' })
  window.open(routerUrl.href, '_blank')
}
// cancel------------------------------------------
// 表格cancel
// const cancelSingle = async (cancelId) => {
//   load.show('Canceling the shipment(s)...')
//   const msg = await shipmentCancel({
//     id: cancelId
//   })
//   if (msg.code === 0) {
//     load.hide()
//     Notification.success({
//       title: 'Success',
//       content: 'The shipment(s) has/have been canceled.'
//     })
//     currentpage.value = 1
//     getShipmentList()
//   } else {
//     load.hide()
//     Notification.error({
//       title: 'Error',
//       content: msg.message
//     })
//   }
// }
// tracking cancel
const trackCancel = async (shipmentId) => {
  trackingVisible.value = false
  load.show('Canceling the shipment(s)...')
  const msg = await shipmentCancel({
    id: shipmentId
  })
  console.log(msg)
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The shipment(s) has/have been canceled.'
    })
    currentpage.value = 1
    getShipmentList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 批量cancel
const moreCancel = async () => {
  load.show('Canceling the shipment...')
  const msg = await shipmentBatchCancel({
    ids: changeform.operate_id
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'The shipment has been canceled.'
    })
    changeform.operate_id = []
    currentpage.value = 1
    getShipmentList()
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
// 点击搜索
const searchShipment = () => {
  currentpage.value = 1
  getShipmentList()
}

// 点击重置
const handleReset = () => {
  searchForm.tracking = ''
  searchForm.status = ['awaitShip', 'callback', 'canceled', 'changeAddress', 'delivered', 'dispatching', 'exceptional', 'inTransit']
  searchForm.service = [0, 101, 102]
  searchForm.showPieces = [0, 1]
  searchForm.manifest_name = ''
  searchForm.recipientZip = ''
  searchForm.orderId = ''
  searchForm.createTime = ['', '']
  searchForm.manifest_id = ''
  searchForm.category = ''
  currentpage.value = 1
  getShipmentList()
}
// 表格选择---------------------------------
const changeDisable = ref(true)
const callDisable = ref(true)
const cancelDisable = ref(true)
// 多选
const changmsg = (checked) => {
  checkedValue.value = checked
  if (checked) {
    changeform.operate_id = []
    // 选中的所有key
    listData.value.forEach((item) => {
      changeform.operate_id.push(item.key)
    })
    // 判断是否可以做批量操作
    if (listData.value.every(item => { return item.changeaddress === true })) {
      changeDisable.value = false
    }
    if (listData.value.every(item => { return item.clallback === true })) {
      callDisable.value = false
    }
    if (listData.value.every(item => { return item.cancel === true })) {
      cancelDisable.value = false
    }
  } else {
    changeform.operate_id = []
    changeDisable.value = true
    callDisable.value = true
    cancelDisable.value = true
  }
}

// 单选
function selectVal (rowKeys) {
  changeform.operate_id = rowKeys
  if (rowKeys.length === listData.value.length) {
    checkedValue.value = true
  } else {
    checkedValue.value = false
  }
  // 循环listData.value，选出选中的数组
  let newArr = []
  for (let i = 0; i < rowKeys.length; i++) {
    for (let j = 0; j < listData.value.length; j++) {
      if (rowKeys[i] === listData.value[j].key) {
        newArr.push(listData.value[j])
      }
    }
  }
  if (newArr.length > 0) {
    // 判断是否可以进行按钮操作
    if (newArr.every(item => { return item.changeaddress === true })) {
      changeDisable.value = false
    } else {
      changeDisable.value = true
    }
    if (newArr.every(item => { return item.clallback === true })) {
      callDisable.value = false
    } else {
      callDisable.value = true
    }
    if (newArr.every(item => { return item.cancel === true })) {
      cancelDisable.value = false
    } else {
      cancelDisable.value = true
    }
  } else {
    changeDisable.value = true
    callDisable.value = true
    cancelDisable.value = true
  }
}
// 展示弹框设置----------------------------------------------------
const setFlag = ref(false)
const setSelection = {
  type: 'checkbox',
  showCheckedAll: true
}
// 展示的所有数据
let leftData = ref([
  {
    key: 'tracking_id',
    title: 'Tracking ID',
    dataIndex: 'tracking_id',
    slotName: 'tracking',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 180
  },
  {
    key: 'ponumber',
    title: 'PO Number',
    dataIndex: 'ponumber',
    slotName: 'ponumber',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 220
  },
  {
    key: 'recipientname',
    title: 'Recipient Name',
    dataIndex: 'recipientname',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 200
  },
  {
    key: 'created_at',
    title: 'Created At',
    dataIndex: 'created_at',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal
        if (a.key > b.key) {
          sortVal = 1
        } else if (a.key < b.key) {
          sortVal = -1
        } else {
          sortVal = 0
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal
      }
    },
    width: 200
  },
  {
    key: 'category',
    title: 'Status',
    dataIndex: 'category',
    slotName: 'category',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 180
  },
  {
    key: 'sku',
    title: 'Sku',
    dataIndex: 'sku',
    slotName: 'sku',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    key: 'dimension',
    title: 'Dimension(In)',
    dataIndex: 'dimension',
    slotName: 'dimension',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    key: 'weight',
    title: 'Weight(Lb)',
    dataIndex: 'weight',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 150
  },
  {
    key: 'declaredvalue',
    title: 'Declared Value',
    slotName: 'declaredvalue',
    dataIndex: 'declaredvalue',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 180
  },
  {
    key: 'estcost',
    title: 'Est.Cost',
    dataIndex: 'estcost',
    slotName: 'estcost',
    sortable: {
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b, extra) => {
        let sortVal;
        if (parseFloat(a.estcost) > parseFloat(b.estcost)) {
          sortVal = 1;
        } else if (parseFloat(a.estcost) < parseFloat(b.estcost)) {
          sortVal = -1;
        } else {
          sortVal = 0;
        }
        return (extra.direction === 'ascend') ? sortVal : -sortVal;
      }
    },
    width: 120
  },
  {
    key: 'estimateddeliverydate',
    title: 'Estimated Delivery Date',
    dataIndex: 'estimateddeliverydate',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 240
  },
  {
    key: 'delivereddate',
    title: 'Delivered Date',
    dataIndex: 'delivereddate',
    sortable: { sortDirections: ['ascend', 'descend'] },
    width: 200
  }
])
// 改变排序
const setArr = reactive({
  val: ['tracking_id', 'ponumber', 'category', 'recipientname', 'created_at', 'category', 'sku', 'dimension', 'weight', 'declaredvalue', 'estcost']
})
const handleChange = (_data) => {
  leftData.value = _data
  let newArr = ref([])
  _data.forEach(item => {
    if (setArr.val.indexOf(item.key) !== -1) {
      newArr.value.push(item.key)
    }
  })
  setArr.val = newArr.value
}
const isSave = ref(true)
// 设置全选
const selectAll = (checked) => {
  if (checked) {
    setArr.val = []
    leftData.value.forEach(item => {
      setArr.val.push(item.key)
    })
    isSave.value = false
  } else {
    setArr.val = []
    isSave.value = true
  }
  defaultSelect.value = setArr.val
}

// 设置单选
const setSelect = (rowKeys) => {
  if (rowKeys.length > 0) {
    setArr.val = []
    leftData.value.forEach(item => {
      if (rowKeys.indexOf(item.dataIndex) !== -1) {
        setArr.val.push(item.key)
      }
    })
  } else {
    setArr.val = []
  }
  defaultSelect.value = setArr.val
}
// 点击其他地方关闭设置
onMounted(() => {
  let box = document.getElementsByClassName('transfer')[0]
  let btn = document.getElementById('setBtn')
  btn.addEventListener('click', function (e) {
    setFlag.value = !setFlag.value
    e.stopPropagation()
  })
  box.addEventListener('click', function (e) {
    e.stopPropagation()
  })
  document.addEventListener('click', function (e) {
    setFlag.value = false
  })
})
// 点击default
const defaultSelect = ref(['tracking_id', 'ponumber', 'category', 'recipientname', 'created_at', 'sku', 'dimension', 'weight', 'declaredvalue', 'estcost'])
const setDefault = () => {
  defaultSelect.value = ['tracking_id', 'ponumber', 'category', 'recipientname', 'created_at', 'sku', 'dimension', 'weight', 'declaredvalue', 'estcost']
  setArr.val = ['tracking_id', 'ponumber', 'recipientname', 'created_at', 'category', 'sku', 'dimension', 'weight', 'declaredvalue', 'estcost']
}
// 点击设置
const setVal = () => {
  const action = ref([{
    title: 'Action',
    dataIndex: 'action',
    slotName: 'action',
    fixed: 'right',
    width: 150
  }])
  let saveArr = ref([])
  for (let i = 0; i < setArr.val.length; i++) {
    for (let j = 0; j < leftData.value.length; j++) {
      if (leftData.value[j].dataIndex === setArr.val[i]) {
        saveArr.value.push(leftData.value[j])
      }
    }
  }
  saveArr.value = saveArr.value.concat(action.value)
  listColumns.value = saveArr.value
  Notification.success({
    title: 'Success',
    content: 'Saved.'
  })
  setFlag.value = false
}
// 点击复制
const copyVal = (val) => {
  const input = document.createElement("input")
  document.body.appendChild(input)
  input.setAttribute("value", val)
  input.select()
  if (document.execCommand("copy")) {
    document.execCommand("copy")
    Message.success(val + '  ' + 'copied.')
  }
  document.body.removeChild(input)
}
const LookTrackLabel = () => {
  window.open(currentView.value, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
}
const goDetail = (tracking, url) => {
  drawerTracking.value = tracking
  currentView.value = url
  rowDetail()
}
// 新订单详情
const newOrderDetail = (newId) => {
  let routerUrl = router.resolve({ path: '/order/list/listdetail', query: { orderId: newId } })
  window.open(routerUrl.href, '_blank')
}
const checkedValue = ref(false)
const selectPage = (val) => {
  pageSize.value = val
  getShipmentList()
}

const downloadsign = () => {
  window.open(proofReceipt.value, '_blank', 'top=0,left=0,width=500,height=880,toolbar=0,resizable=0,statusbar=0,scrollbars=0')
}
const downloadImg = () => {
  let image = new Image()
  image.setAttribute("crossOrigin", "anonymous")
  image.onload = function () {
    let canvas = document.createElement("canvas")
    canvas.width = image.width
    canvas.height = image.height
    let context = canvas.getContext("2d")
    context.drawImage(image, 0, 0, image.width, image.height)
    let url = canvas.toDataURL("image/png")
    let a = document.createElement("a")
    let event = new MouseEvent("click")
    a.download = "POD_" + trackData.trackVal.tracking_id
    a.href = url
    a.dispatchEvent(event)
  }
  image.src = proofReceipt.value + '?' + Math.random
}

const numReg = /^[0-9]*$/
const zipOptions = ref([])
const zipNoarea = ref(false)
const zipIsempty = ref(true)
const zipZiperror = ref(false)
const zipLoading = ref(false)
const zipaddress = ref(false)
const ZipSearch = async (value) => {
  if (!value) {
    zipIsempty.value = true
    zipNoarea.value = false
    zipZiperror.value = false
  } else {
    zipIsempty.value = false
  }
  if (!numReg.test(value)) {
    zipZiperror.value = true
    zipNoarea.value = false
  } else {
    zipZiperror.value = false
    if (value.length > 2) {
      zipLoading.value = true
      const msg = await getZipCode({
        term: value
      })
      if (msg.data.length > 0) {
        zipLoading.value = false
        zipNoarea.value = false
        zipOptions.value = []
        zipaddress.value = msg.data
        for (let i = 0; i < msg.data.length; i++) {
          zipOptions.value.push(msg.data[i].zip)
        }
      } else {
        zipLoading.value = false
        zipOptions.value = []
        zipaddress.value = []
        zipNoarea.value = true
      }
    }
  }
}
const zipChange = (value) => {
  // console.log(zipOptions.value)
  if (zipaddress.value !== []) {
    zipaddress.value.forEach((item) => {
      if (item.value === value) {
        changeform.to_city = item.city
        changeform.to_state = item.state
        changeform.to_zip = item.zip
      }
    })
  }
}
// 时间设置--------------------------------------------------
let dateFormate = ref('MM/DD/YYYY')
let timeFormate = JSON.parse(local.get('userInfo')).date_format
// console.log(timeFormate)
if (timeFormate === 1) {
  dateFormate.value = 'MM/DD/YYYY'
} else if (timeFormate === 2) {
  dateFormate.value = 'DD/MM/YYYY'
} else if (timeFormate === 3) {
  dateFormate.value = 'YYYY/MM/DD'
}

// 投诉
const goClaim = () => {
  router.push('/usercenter/claim')
}
// 添加备注
const whoVisible = JSON.parse(local.get('configInfo')).shipment_note_visible
const noteVisible = ref(false)
const noteRef = ref(null)
const noteForm = reactive({
  visible: [],
  content: ''
})
const openNote = () => {
  noteVisible.value = true
}
const cancelNote = () => {
  noteVisible.value = false
  noteRef.value.resetFields()
}
const resetNote = () => {
  noteRef.value.resetFields()
}
const submitNote = () => {
  noteRef.value.validate(async (record) => {
    if (!record) {
      const msg = await shipmentAddNote({
        visible: noteForm.visible,
        shipment_id: trackData.trackVal.shipment_id,
        content: noteForm.content
      })
      console.log(msg)
      if (msg.code === 0) {
        Notification.success('Submitted.')
        noteVisible.value = false
        noteRef.value.resetFields()
        rowDetail()
      } else {
        Notification.error(msg.message)
      }
    }
  })
}
</script>

<style lang="less" scoped>
.empty {
  color: rgb(var(--danger-6));
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

.noarea {
  color: var(--color-text-4);
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

.m-r-8 {
  margin-right: 8px;
}

.shipment-box {
  position: relative;
}

.margin-l-10 {
  cursor: pointer;
  width: 12px;
  height: 12px;
  color: #C9CDD4;
  padding-left: 4px;
}

.searchOrder {
  padding: 0 24px;
}

.list-title {
  font-weight: 500;
  font-size: 20px;
  color: var(--color-text-1);
  padding: 18px 0 18px 0;
}

.operate {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 82px;
}

.statType {
  font-weight: 500;
  font-size: 16px;
  padding-right: 20px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

// #shipMap {
//   height: 255px;
// }
.proofImg {
  position: relative;

  .proof-til {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
    padding-bottom: 12px;
  }

  img {
    height: 255px;
    width: 100%;
    border-radius: 12px;
  }

  .proof-footer {
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    bottom: 6px;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    text-align: right;
    line-height: 50px;
    margin-right: 20px;
    z-index: 2;
    border-radius: 0 0 12px 12px;

    svg {
      cursor: pointer;
    }
  }
}

:deep(.logs .arco-timeline .arco-timeline-item:first-child .arco-timeline-item-content) {
  font-weight: bold;

  .arco-typography {
    font-weight: normal;
  }
}

// 备注
.notice {

  .til {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
  }

  .notice-cont {
    padding-top: 16px;

    .who {
      margin-bottom: 4px;
      color: var(--color-text-1);
      font-size: 14px;
      line-height: 1.5715;
    }

    .cont {
      font-size: 12px;
      margin-top: 4px;
      color: var(--color-text-2);
      line-height: 1.5715;
    }

    .time {
      color: var(--color-text-3);
      font-size: 12px;
      line-height: 1.667;
    }
  }
}

// 日志
.logistics {
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;

  .road-steps {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
  }

  .proof {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: var(--color-text-3);

    img {
      width: 32px;
      height: 32px;
      padding-left: 8px;
    }
  }
}

.proof-title {
  padding: 11px 16px;
}

.content-img {
  position: relative;

  img {
    width: 360px;
    height: 204px;
  }
}

.change-title {
  text-align: center;
  width: 100%;

  .til {
    font-weight: 500;
    font-size: 16px;
    color: var(--Text-5);
  }

  .track {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-3);
    padding-left: 8px;
  }
}

.change-footer {
  text-align: center;
  width: 100%;
}

.table-box {
  position: relative;

  //穿梭框
  .transfer {
    position: absolute;
    bottom: 20px;
    right: 0;
    background-color: rgba(255, 255, 255, 0.97);
    z-index: 999;

    .setList {
      position: relative;

      :deep(.arco-table-container thead>tr th:first-child) {
        display: none;
      }

      :deep(.arco-table-container thead>tr th:nth-child(3))>.arco-table-cell {
        width: 100px;
        font-size: 14px;
        color: #1d2129;
        font-weight: 500;
      }

      :deep(.arco-table-container .arco-table-content) {
        border: 1px solid #E5E6EB;
        border-bottom: none;
        border-radius: 0;
      }

      :deep(.arco-table-container) {
        border-radius: 0 !important;
      }

      :deep(.arco-table-header) {
        height: 40px;
        background: #F7F8FA;
      }

      :deep(.arco-table-tr) {
        height: 40px;
      }

      :deep(.arco-table-tr .arco-table-td) {
        border: 0;
        color: #1d2129;
      }

      :deep(.arco-table .arco-table-cell) {
        padding: 9px 0;
      }

      :deep(.arco-table-tr-checked .arco-table-td) {
        background: #fff !important;
      }

      .SelectNum {
        position: absolute;
        top: 12px;
        right: 8px;
        font-weight: 400;
        font-size: 12px;
        color: #86909C;
      }

      .transfer-btn {
        height: 40px;
        background: #fff;
        border: 1px solid #E5E6EB;
        border-radius: 0 0 4px 4px;
        width: 218px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          margin: 0 8px;
        }
      }
    }
  }
}

.add-page {
  display: flex;
  justify-content: flex-end;

  .arco-btn-text.arco-btn-disabled,
  .arco-btn-text[type='button'].arco-btn-disabled {
    color: var(--color-text-4);
  }

  .arco-btn-text,
  .arco-btn-text[type='button'] {
    color: var(--color-text-2);
  }
}

.addnew-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 20px;
  height: 72px;
  border-top: 1px solid var(--color-border-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 999;
}

.change-title {
  text-align: center;
  width: 100%;

  .til {
    font-weight: 500;
    font-size: 16px;
    color: var(--Text-5);
  }

  .track {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-3);
    padding-left: 8px;
  }
}

.change-footer {
  text-align: center;
  width: 100%;
}

.empty-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    color: rgba(28, 31, 35, 0.8);
    font-weight: 400;
    font-size: 14px;
    padding-top: 30px;
  }
}

.currentpage {
  color: rgb(var(--primary-6));
  background-color: var(--color-primary-light-1);
  padding: 1px 8px;
  display: inline-block;
  box-sizing: border-box;
}
</style>
<style>
.warnPop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--warning-6));
  background-color: rgb(var(--warning-1));
}

.deletePop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--danger-6));
  background-color: rgb(var(--danger-1));
}
</style>
